import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { Article, BlogSectionImage, isBlogSectionImage } from '../../types/blog'
// Utils
import { getUrlToShare } from '../../utils/commons'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Layout, Seo } from '../../components/commons'
import { Box } from '../../atoms'
import { ArticleHero, SectionBlog, ShareBar } from '../../components/blog'
import NewsLetter from '../../components/commons/NewsLetter'

interface DataQuery {
  article: Article
}

const BlogArticle: React.FC<PageProps<DataQuery>> = ({ data }) => {
  const { article } = data

  // Structured Data
  const blogImageSections: string[] = article.sections
    .filter<BlogSectionImage>(isBlogSectionImage)
    .map((s) => s.image.url)

  const blogImages: string[] =
    article.cover && article.cover.url ? [article.cover.url, ...blogImageSections] : [...blogImageSections]

  const BlogSDataSchema = {
    pageType: 'NewsArticle',
    headline: article.title,
    image: blogImages,
  }

  return (
    <Layout>
      <Seo
        slug={article.slug}
        publication={article.meta}
        lang={article.locale}
        content={
          article.seo || {
            title: article.title,
            description: article.excerpt,
            twitterCard: null,
            image: null,
          }
        }
        SDSchema={BlogSDataSchema}
      />
      {/* Page wrapper */}
      <Box pt={[100, 120]}>
        {/* Article */}
        <Box position={'relative'}>
          <Box
            position={'absolute'}
            right={[
              'calc((100vw - 840px)/2)',
              'calc((100vw - 840px)/2)',
              'calc((100vw - 960px)/2)',
              'calc((100vw - 960px)/2)',
            ]}
            height={'100%'}
            width={'40px'}
            display={['none', 'none', 'block']}
          >
            <Box position={'sticky'} top={120} width={'40px'} color={'navyBlue'}>
              <ShareBar urlToShare={getUrlToShare()} direction={'column'} />
            </Box>
          </Box>
          <Box as={'article'}>
            <Grid>
              <Row center={'xs'}>
                <Col xs={12} sm={10} lg={8}>
                  <ArticleHero
                    title={article.title}
                    authors={article.authors}
                    categories={article.categories}
                    metaFormatted={article.metaFormatted}
                    cover={article.cover}
                  />
                  {article.sections.map(
                    (s) => s?.model && <SectionBlog key={s.id} sectionApiKey={s.model.apiKey} data={s} />
                  )}
                </Col>
              </Row>
            </Grid>
          </Box>
        </Box>
        {article.showNewsLetterSection && <NewsLetter />}
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($slug: String) {
    article: datoCmsArticle(slug: { eq: $slug }) {
      ...articleFragment
    }
  }
`

export default BlogArticle
